import { action } from "mobx";
import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";

export class AuthStore {
  @action
  async access(email: string) {
    const url = `${Configs.apiUrl}/auth/access`;
    try {
      const result: any = await axios.post(url, { email });
      if (!result || !result["data"]) return generalError;
      return result["data"]["success"];
    } catch (e) {
      return generalError;
    }
  }
}

export const STORE_AUTH = "authStore";
