import React, { useEffect } from "react";
import Web3Modal from "web3modal";
import * as Configs from "../../config";
import { openNotificationWithIcon } from "../notification";
import { PayBtn } from "./pay-btn";
import { useTranslation } from "react-i18next";
const { useEthers } = require("@usedapp/core");

export const MintBtn = (props: any) => {
  const { t } = useTranslation();
  const {
    setLoading,
    setStage,
    email,
    plan,
    depositToken,
    createPendingOrder,
    updateOrder,
    loading,
  } = props;
  const { account, activate, error } = useEthers();

  useEffect(() => {
    if (error && account) {
      return openNotificationWithIcon(
        "warning",
        t("notification.sorry"),
        error.message
      );
    }
  }, [error, account, t]);

  const activateProvider = async () => {
    const providerOptions = Configs.providerOptions;

    const web3Modal = new Web3Modal({
      providerOptions,
    });
    try {
      const provider = await web3Modal.connect();
      await activate(provider);
      return openNotificationWithIcon(
        "success",
        t("notification.success.title"),
        t("notification.success.walletConnected")
      );
    } catch (error) {
      const msg: any = error;
      return openNotificationWithIcon(
        "warning",
        t("notification.sorry"),
        msg.message
      );
    }
  };

  return (
    <div className="web3-btn">
      {!account && (
        <button className="primary full" onClick={() => activateProvider()}>
          {t("mint.connect")}
        </button>
      )}
      {account && depositToken && (
        <PayBtn
          depositToken={depositToken}
          account={account}
          setLoading={setLoading}
          setStage={setStage}
          plan={plan}
          email={email}
          createPendingOrder={createPendingOrder}
          updateOrder={updateOrder}
          loading={loading}
        />
      )}
    </div>
  );
};

export const checking = (chainId: any, depositToken: string, t?: any) => {
  if (chainId !== Configs.getChain().id) {
    openNotificationWithIcon(
      "warning",
      t("notification.sorry"),
      t("notification.warning.walletNetworkErr", { network: Configs.NETWORK })
    );
    return false;
  }
  if (!depositToken) {
    openNotificationWithIcon(
      "warning",
      t("notification.sorry"),
      t("notification.warning.notSelectToken")
    );
    return false;
  }
  return true;
};

export const checkPlanPrice = (
  plan: string,
  balance: any,
  address: string,
  t?: any
) => {
  const currentBalance = parseFloat(balance);
  let isEnoughPrice = false;
  switch (plan) {
    case "jade":
      if (currentBalance >= 2500) isEnoughPrice = true;
      break;
    case "platinum":
      if (currentBalance >= 30000) isEnoughPrice = true;
      break;
    case "diamond":
      if (currentBalance >= 150000) isEnoughPrice = true;
      break;
    default:
      break;
  }

  if (Configs.NETWORK !== "mainnet" && currentBalance >= 1)
    isEnoughPrice = true;

  if (!isEnoughPrice) {
    openNotificationWithIcon(
      "warning",
      t("notification.sorry"),
      t("notification.warning.balanceNotEnough")
    );
    return false;
  }

  return true;
};

export const getPlanPrice = (plan: string, address: string) => {
  if (Configs.NETWORK !== "mainnet") return 1;
  switch (plan) {
    case "jade":
      return 2500;
    case "platinum":
      return 30000;
    case "diamond":
      return 150000;
    default:
      return 99999999;
  }
};
